import { Injectable, signal } from '@angular/core';
import { UsersApiService } from '@api/users-api.service';
import { UserDto } from '@typesBase/user-dto';
import { Observable, tap } from 'rxjs';

@Injectable()
export class UserService {
  currentUser = signal<UserDto | null>(null);

  constructor(private userApiService: UsersApiService) {}

  getUserProfile(): Observable<UserDto> {
    return this.userApiService.getUserProfile().pipe(
      tap((res) => {
        this.currentUser.set(res);
      }),
    );
  }
}
