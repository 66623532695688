import { CanActivateFn, Router } from '@angular/router';
import { AuthApiService } from '@api/auth-api.service';
import { inject } from '@angular/core';
import { PAGE_ROUTES } from '@enums/page-routes.enum';

export const InitializationGuard: CanActivateFn = (): boolean => {
  const authService = inject(AuthApiService);
  const router = inject(Router);

  if (!authService.accessToken) {
    router.navigate(['/' + PAGE_ROUTES.LOADING]).then();
    authService.login();

    return false;
  }
  return true;
};
